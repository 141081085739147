import axios from './axios';

export default {
  create: (data) => axios.post('/api/pre-reg-users/', { data }),
  getTheApp: (data) =>
    axios.post('/api/pre-reg-users/get-the-app', {
      data,
      options: {
        disableAuthorization: true,
        // eslint-disable-next-line
        baseURL: process.env.REACT_APP_API_HOST
      }
    })
};
