import React, { useEffect, useRef } from 'react';
import { Box, Input, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPhoneInput from './ReactPhoneInput';
import { COLORS, FONTS } from 'config/constants';
import CustomButton from 'components/Button/CustomButton';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';

const useStyles = makeStyles((theme) => ({
  invert: {
    [theme.breakpoints.down('md')]: {
      '&$container': {
        position: 'relative',
        justifyContent: 'center'
      }
    },
    [theme.breakpoints.up('md')]: {
      '&$container': {
        flexDirection: 'column'
      },
      '&$button': {
        alignSelf: 'flex-end',
        transform: 'translateX(0px)',
        '&:not(:hover)': {
          transition: 'transform 0.3s'
        },
        '&$buttonInitial': {
          transform: 'translateX(-212px)',
          '&:hover': {
            transform: 'translateX(-212px) scale(1.1)'
          }
        }
      },
      '&$input': {
        alignSelf: 'flex-start',
        '&$hide': {
          width: 186
        },
        '&$show': {
          transition: 'width 0.3s'
        }
      }
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'end',
    borderRadius: '50px',
    alignItems: 'center',
    width: 397,
    height: 60,
    [theme.breakpoints.up('xl')]: {
      width: 477,
      height: 72
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute'
    },
    [theme.breakpoints.down('sm')]: {
      width: 317,
      height: 48,
      justifyContent: 'flex-end'
    }
  },
  hide: {
    '&$input': {
      width: 97,
      opacity: 0,
      position: 'relative'
    }
  },
  show: {
    '&$input': {
      position: 'absolute'
    }
  },
  input: {
    position: 'absolute',
    transition: 'width 0.3s',
    width: '100%',
    height: '100%',
    borderRadius: 50,
    padding: '15px 0px 15px 20px',
    backgroundColor: COLORS.white,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.4,
    lineHeight: 0.7,
    color: COLORS.black,
    boxShadow: '0px 2px 4px 0px #00000040',
    [theme.breakpoints.up('xl')]: {
      fontSize: 25,
      letterSpacing: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '&::placeholder': {
      color: COLORS.grey1
    },
    '& input': {
      paddingRight: '109px'
    }
  },
  buttonInitial: {
    '&$button': {
      width: 186,
      height: 60,
      opacity: 0.6,
      position: 'absolute',
      boxShadow: '0px 2px 4px 0px #00000040',
      [theme.breakpoints.up('xl')]: {
        width: 268,
        height: 72
      },
      [theme.breakpoints.down('sm')]: {
        width: 163,
        height: 48,
        fontSize: 16
      },
      '&:hover': {
        transform: 'scale(1.1)'
      },
      '& span': {
        width: '186px',
        position: 'absolute',
        [theme.breakpoints.up('xl')]: {
          width: 188
        }
      }
    }
  },
  button: {
    width: 97,
    height: 62,
    marginTop: -1,
    marginRight: -1,
    boxShadow: 'none',
    transition: 'width 0.4s',
    [theme.breakpoints.up('xl')]: {
      width: 101,
      height: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 93,
      height: 48
    }
  },
  checkPhoneTextContainer: {
    margin: '0px 175px 55px 0px',
    [theme.breakpoints.up('xl')]: {
      margin: '0px 325px 100px 0px'
    }
  },
  checkPhoneTextContainerFooter: {
    position: 'absolute',
    margin: '200px 0px 0px 40px',
    [theme.breakpoints.up('xl')]: {
      margin: '277px 0px 0px 60px'
    }
  },
  checkPhoneText: {
    color: COLORS.white,
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 11,
    lineHeight: 0.73,
    letterSpacing: 1.1,
    [theme.breakpoints.up('xl')]: {
      fontSize: 15,
      lineHeight: 1.35
    }
  }
}));

const GetTheAppButton = ({
  containerStyle,
  inputStyle,
  initialTitle,
  value,
  setValue,
  autoFocus,
  showInput,
  leftToRight,
  onBlur,
  placeholder,
  type,
  fullWidth,
  disableUnderline,
  onInitialButtonClick,
  onButtonClick,
  title,
  isLoading,
  showError,
  autoComplete,
  showSuccess
}) => {
  const inputRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (showInput && inputRef.current) inputRef.current.focus();

    return () => {
      setValue('');
    };
  }, [showInput]);

  const onValidate = () => {
    if (isValidPhoneNumber(value)) {
      onButtonClick(value);
    } else {
      showError({
        title: 'Invalid phone number!',
        content: 'Please enter a valid US phone number',
        action: 'Dismiss'
      });
    }
  };

  return (
    <>
      <Box
        className={clsx(
          classes.container,
          leftToRight && classes.invert,
          containerStyle
        )}
      >
        <Input
          inputRef={inputRef}
          className={clsx(
            classes.input,
            showInput ? classes.show : classes.hide,
            leftToRight && classes.invert,
            inputStyle
          )}
          value={value}
          onChange={setValue}
          autoFocus={autoFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          fullWidth={fullWidth}
          disableUnderline={disableUnderline}
          autoComplete={autoComplete}
          inputComponent={ReactPhoneInput}
        />
        <CustomButton
          buttonStyle={clsx(
            classes.button,
            !showInput && classes.buttonInitial,
            leftToRight && classes.invert
          )}
          rounded
          onClick={
            showInput ? () => onValidate() : () => onInitialButtonClick()
          }
          title={showInput ? title : initialTitle}
          loading={isLoading}
        />
      </Box>
      {showSuccess && (
        <Box
          className={clsx(
            classes.checkPhoneTextContainer,
            leftToRight && classes.checkPhoneTextContainerFooter
          )}
        >
          <Typography className={classes.checkPhoneText}>
            Check Your Phone
          </Typography>
        </Box>
      )}
    </>
  );
};

GetTheAppButton.defaultProps = {
  containerStyle: '',
  inputStyle: '',
  value: '',
  initialTitle: 'Get The App',
  setValue: () => {},
  autoFocus: true,
  showInput: false,
  leftToRight: false,
  onBlur: () => {},
  placeholder: 'Enter phone number',
  type: 'tel',
  fullWidth: true,
  disableUnderline: true,
  onInitialButtonClick: () => {},
  onButtonClick: () => {},
  title: 'Send',
  isLoading: false,
  showError: () => {},
  autoComplete: 'off',
  showSuccess: false
};

GetTheAppButton.propTypes = {
  containerStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  initialTitle: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  autoFocus: PropTypes.bool,
  showInput: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  leftToRight: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  onInitialButtonClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  showError: PropTypes.func,
  autoComplete: PropTypes.string,
  showSuccess: PropTypes.bool
};

export default GetTheAppButton;
