import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import uuid from 'uuid/v1';

import palette from 'theme/palette';
import { CardView, GridContainer, GridItem } from '../index';
import { useRouter } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(12, 0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 0)
    }
  },
  itemCenter: {
    justifyContent: 'center'
  },
  descWrap: {
    padding: theme.spacing(1)
  },
  description: {
    color: palette.primary
  },
  blogsWrap: {
    margin: theme.spacing(1, 0)
  }
}));

const FBlogSection = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { description, blogs } = props.data;

  return (
    <div>
      <div className={classes.root}>
        <GridContainer>
          <GridItem lg={2} />
          <GridItem
            lg={9}
            xs={12}
          >
            <div className={classes.descWrap}>
              <Typography
                className={classes.description}
                variant="body2"
              >
                {description}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer
          className={classes.blogsWrap}
          justifyContent="center"
        >
          <GridItem
            className={classes.itemCenter}
            container
            lg={10}
            xs={12}
          >
            {blogs.map((item) => (
              <GridItem
                key={uuid()}
                lg={4}
                md={4}
                xs={6}
              >
                <CardView
                  buttonText={item.buttonText}
                  description={item.description}
                  image={item.image}
                  key={uuid()}
                  onClick={() => {
                    item.isExternal
                      ? window.open(item.path, '_self')
                      : router.history.push(item.path);
                  }}
                  title={item.title}
                />
              </GridItem>
            ))}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

FBlogSection.defaultProps = {};

FBlogSection.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    blogs: PropTypes.array
  })
};

export default FBlogSection;
