import { createActions, handleActions } from 'redux-actions';

const initState = {
  isLoading: false
};

export const creators = createActions({
  SET_IS_LOADING: (isLoading) => ({ isLoading }),
  GET_APP_REQUEST: ({ phoneNumber, successCb }) => ({ phoneNumber, successCb })
});

const setIsLoading = (state, { payload: { isLoading } }) => {
  return { ...state, isLoading };
};

export default handleActions(
  {
    SET_IS_LOADING: setIsLoading
  },
  initState
);
