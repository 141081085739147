import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import data from './data';
import { useStyles } from './styles';

const SectionSocials = () => {
  const classes = useStyles();
  const { socials } = data;

  return (
    <Grid className={classes.ssContainer} container>
      {socials.map(({ key, url, icon, target }) => (
        <Grid key={key} className={classes.ssIconContainer} item xs={1}>
          <IconButton
            className={classes.ssIcon}
            onClick={() => window.open(url, target)}
            disableTouchRipple
            disableFocusRipple
            disableRipple
          >
            {icon}
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default SectionSocials;
