import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Modal from 'components/Modal';
import CustomButton from 'components/Button';

const useStyles = makeStyles((theme) => ({
  root: {},
  modalButtonContainer: {
    width: 150,
    margin: 'auto',
    marginTop: theme.spacing(3)
  }
}));

const InfoModal = ({ className, trigger: Trigger, triggerText, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const _toggleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      {Trigger ? (
        <Trigger
          className={className}
          onClick={_toggleModal}
        >
          {triggerText}
        </Trigger>
      ) : (
        <IconButton
          className={className}
          ml={1}
          onClick={_toggleModal}
        >
          <InfoIcon color="secondary" />
        </IconButton>
      )}

      <Modal
        bordered
        classes={{
          root: clsx(classes.root)
        }}
        footer={
          rest.iconClose ? null : (
            <div className={classes.modalButtonContainer}>
              <CustomButton
                onClick={_toggleModal}
                rounded
                title="Close"
              />
            </div>
          )
        }
        icon="/images/info.svg"
        id="more-data"
        onClose={_toggleModal}
        open={open}
        {...rest}
      />
    </>
  );
};

InfoModal.defaultProps = {
  className: null
};

InfoModal.propTypes = {
  className: PropTypes.string,
  iconClose: PropTypes.bool,
  trigger: PropTypes.any,
  triggerText: PropTypes.string
};

export default InfoModal;
