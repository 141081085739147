import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ClickAwayListener
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from './data';
import { COLORS, GLOBALS } from 'config/constants';
import clsx from 'clsx';
import { BgDrawer } from 'assets/images/pngs';
import colors from 'config/constants/colors';
import { useEffect } from 'react';

const drawerWidth = 243;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  navBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: colors.black,
    opacity: 0.4,
    zIndex: 11
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `url(${BgDrawer})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: 'none'
  },
  activeItem: {
    '&>span': {
      color: COLORS.blue
    }
  },
  listItemText: {
    margin: '0 0 0 0',
    '& span': {
      font: 'normal normal bold 12px/16px Roboto',
      letterSpacing: '1.2px',
      color: colors.white,
      textTransform: 'uppercase'
    }
  },
  listItemLinkText: {
    margin: '0 0 0 0',
    '& span': {
      font: 'normal normal 900 12px/16px Roboto',
      letterSpacing: '1.2px',
      color: colors.white,
      textTransform: 'uppercase',
      textDecoration: 'underline'
    }
  },
  signupButton: {
    maxWidth: 85,
    '& span': {
      borderRadius: 50,
      backgroundColor: COLORS.white,
      color: COLORS.blue1,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      padding: '2px 14px'
    }
  },
  contactSalesButton: {
    maxWidth: 150,
    '& span': {
      borderRadius: 50,
      backgroundColor: COLORS.white,
      color: COLORS.blue1,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      padding: '2px 14px'
    }
  },
  listItem: {
    padding: '0 22px 38px 0'
  },
  listTop: {
    paddingTop: 136,
    paddingLeft: 40
  },
  listBottom: {
    padding: '0 0 20px 40px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }
}));

const NavDrawer = ({ closeDrawer, open }) => {
  const location = useLocation();
  const classes = useStyles();
  useEffect(() => {
    if (open) {
      document.getElementById('root').style.position = 'fixed';
    } else {
      document.getElementById('root').style.position = 'initial';
    }
  }, [open]);
  return (
    <Box className={classes.root}>
      <Box className={clsx(open && classes.navBackground)} />
      <ClickAwayListener
        onClickAway={(event) => {
          event.preventDefault();
          closeDrawer();
        }}
        mouseEvent={open ? 'onClick' : false}
        touchEvent={open ? 'onTouchEnd' : false}
      >
        <Drawer
          anchor="right"
          classes={{
            paper: classes.drawerPaper
          }}
          open={open}
          variant="persistent"
        >
          <List className={clsx(classes.listTop)}>
            {Menu.menuItems.map((menu, index) => {
              if (
                menu.title === 'Sign Up' &&
                location.pathname == menu.location
              ) {
                return (
                  <ListItem
                    key={index}
                    button
                    className={clsx(classes.listItem)}
                    onClick={() => window.open(GLOBALS.APP_STORE_LINK, '_self')}
                  >
                    <ListItemText
                      className={clsx(
                        classes.listItemText,
                        classes.signupButton
                      )}
                      primary={menu.title}
                    />
                  </ListItem>
                );
              } else if (
                menu.title === 'Contact Sales' &&
                location.pathname == menu.location
              ) {
                return (
                  <Link key={index} to="/contact-sales">
                    <ListItem button className={clsx(classes.listItem)}>
                      <ListItemText
                        className={clsx(
                          classes.listItemText,
                          classes.contactSalesButton
                        )}
                        primary={menu.title}
                      />
                    </ListItem>
                  </Link>
                );
              } else if (
                menu.title === 'Developer Docs' &&
                location.pathname == menu.location
              ) {
                return (
                  <a
                    key={index}
                    // eslint-disable-next-line no-undef
                    href={process.env.REACT_APP_API_DOCS_LINK}
                    rel="noopener noreferer"
                  >
                    <ListItem button className={clsx(classes.listItem)}>
                      <ListItemText
                        className={clsx(classes.listItemText)}
                        primary={menu.title}
                      />
                    </ListItem>
                  </a>
                );
              } else if (
                menu.title !== 'Contact Sales' &&
                location.pathname == menu.location
              ) {
                return (
                  <Link key={index} to={menu.redirect} onClick={closeDrawer}>
                    <ListItem button className={clsx(classes.listItem)}>
                      <ListItemText
                        className={clsx(classes.listItemText)}
                        primary={menu.title}
                      />
                    </ListItem>
                  </Link>
                );
              }
            })}
          </List>
          <List className={clsx(classes.listBottom)}>
            {Menu.footerItems.map((menu, index) => {
              const { link, target, text } = menu;
              if (text === 'DISCLOSURES') {
                return (
                  <Link key={index} to={link} onClick={closeDrawer}>
                    <ListItem button className={clsx(classes.listItem)}>
                      <ListItemText
                        className={clsx(classes.listItemLinkText)}
                        primary={text}
                      />
                    </ListItem>
                  </Link>
                );
              } else {
                return (
                  <ListItem
                    key={index}
                    button
                    className={clsx(classes.listItem)}
                    onClick={() => window.open(link, target)}
                  >
                    <ListItemText
                      className={clsx(classes.listItemLinkText)}
                      primary={text}
                    />
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
      </ClickAwayListener>
    </Box>
  );
};

NavDrawer.propTypes = {
  closeDrawer: PropTypes.func,
  path: PropTypes.string,
  open: PropTypes.bool
};

export default NavDrawer;
