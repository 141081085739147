import React from 'react';
import PropTypes from 'prop-types';
import {
  colors,
  Dialog,
  makeStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { COLORS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      borderRadius: theme.spacing(4)
    }
  },
  wrapper: {
    backgroundColor: COLORS.white,
    minWidth: 500,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      minWidth: 400
      // maxWidth: 560
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
      maxWidth: 360,
      padding: theme.spacing(3)
    }
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  underlinedHeader: {
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.grey[300]
  },
  title: {
    fontWeight: 'bold',
    color: COLORS.black,
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  body: {
    marginTop: theme.spacing(3),
    whiteSpace: 'pre-line'
  },
  bodyTxt: {
    color: COLORS.black
  },
  footer: {
    marginTop: theme.spacing(4)
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    color: theme.palette.grey[500]
  }
}));

const Modal = (props) => {
  const classes = useStyles();
  const {
    open,
    title,
    bordered,
    classes: {
      root: rootCls,
      wrap: wrapCls,
      title: titleCls,
      body: bodyCls,
      footer: footerCls,
      icon: iconCls
    },
    body,
    footer,
    icon,
    iconClose,
    ...rest
  } = props;

  return (
    <Dialog
      className={clsx(classes.root, rootCls, props.className)}
      onClose={props.onClose}
      open={open}
      {...rest}
    >
      <div className={clsx(classes.wrapper, wrapCls)}>
        {(!!title || !!icon) && (
          <div
            className={clsx(
              classes.header,
              bordered && classes.underlinedHeader,
              titleCls,
              'debug--container'
            )}
          >
            {icon && (
              <img
                alt={'icon'}
                className={clsx(classes.icon, iconCls)}
                src={icon}
              />
            )}
            <Typography
              className={classes.title}
              variant="h5"
            >
              {title}
            </Typography>
            {iconClose && (
              <IconButton
                aria-label="close"
                className={classes.closeIcon}
                onClick={props.onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        <div className={clsx(classes.body, bodyCls)}>
          {typeof body === 'string' ? (
            <Typography
              className={classes.bodyTxt}
              variant="body1"
            >
              {body}
            </Typography>
          ) : (
            body
          )}
        </div>

        {footer && (
          <div className={clsx(classes.footer, footerCls)}>{footer}</div>
        )}
      </div>
    </Dialog>
  );
};

Modal.defaultProps = {
  open: false,
  title: '',
  classes: {
    titleCls: 'text-left'
  }
};

Modal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bordered: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.any,
    footer: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    wrap: PropTypes.any,
    icon: PropTypes.any
  }),
  footer: PropTypes.node,
  icon: PropTypes.string,
  iconClose: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node
};

export default Modal;
