
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { DESIGNS } from 'config/constants';

const getPageSpacing = (w) => {

  if (isWidthUp('lg', w)) {
    return DESIGNS.pageSpacing.lg
  }
  if (isWidthUp('md', w)) {
    return DESIGNS.pageSpacing.md
  }
  if (isWidthUp('sm', w)) {
    return DESIGNS.pageSpacing.sm
  }
  if (isWidthDown('sm', w)) {
    return DESIGNS.pageSpacing.xs
  }
  return DESIGNS.pageSpacing.lg
};

const calPagePaddingPixel = (w) => getPageSpacing(w) * 8;

const getGridSpacing = (w) => {

  if (isWidthUp('xl', w)) {
    return DESIGNS.gridSpacing.xl;
  }

  if (isWidthUp('lg', w)) {
    return DESIGNS.gridSpacing.lg;
  }

  if (isWidthUp('md', w)) {
    return DESIGNS.gridSpacing.md;
  }

  if (isWidthUp('sm', w)) {
    return DESIGNS.gridSpacing.sm;
  }

  if (isWidthDown('sm', w)) {
    return DESIGNS.gridSpacing.xs;
  }

  return DESIGNS.gridSpacing.lg;
};

const calGridSpacingPixel = w => getGridSpacing(w) * 8;

const getSlidesToShow = w => {
  if (isWidthUp('xl', w)) {
    return DESIGNS.slidesToShow.lg;
  }

  if (isWidthUp('lg', w)) {
    return DESIGNS.slidesToShow.lg;
  }

  if (isWidthUp('md', w)) {
    return DESIGNS.slidesToShow.md;
  }

  if (isWidthUp('sm', w)) {
    return DESIGNS.slidesToShow.sm;
  }

  if (isWidthDown('sm', w)) {
    return DESIGNS.slidesToShow.xs;
  }

};

const calGridWidthPixel = (w, sw) => {
  const pdX = calPagePaddingPixel(w);
  const gdX = calGridSpacingPixel(w);
  return (sw- 2 * pdX + gdX )/12
};

const calDesignPixels = (w) => {
  return {
    pdX: calPagePaddingPixel(w),
    gdX: calGridSpacingPixel(w)
  }
};

export default {
  getPageSpacing,
  calPagePaddingPixel,
  getGridSpacing,
  calGridSpacingPixel,
  getSlidesToShow,
  calGridWidthPixel,
  calDesignPixels
}
