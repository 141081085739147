import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  greet: {
    marginLeft: -6,
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      marginLeft: -2
    }
  },
  note: {
    marginTop: theme.spacing(2),
    color: COLORS.white,
    lineHeight: 1.4
  }
}));

const Greet = props => {
  const { greet, note } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.row, props.className)}>
      <Typography
        className={classes.greet}
        variant={'h1'}
      >
        {greet}
      </Typography>
      <Typography
        className={classes.note}
        variant={'h5'}
      >
        {note}
      </Typography>
    </div>
  );
};

Greet.propTypes = {
  className: PropTypes.string,
  greet: PropTypes.string,
  note: PropTypes.string,
};

export default Greet;
