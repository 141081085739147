const formatInlineMessage = str => {
  if (!str) return '';
  let temp = str.split('<p>').join('');
  temp = temp.split('</p>').join('');
  temp = temp.replace('&nbsp;', '');
  return temp;
}

export default {
  formatInlineMessage,
}