import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { COLORS } from 'config/constants';

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: COLORS.darkBlue,
    borderRadius: 0,
    fontSize: 16,
    height: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      height: theme.spacing(5)
    },
    color: COLORS.white
  }
}))(Button);

const SubmitButton = ({ loading, children, white, type, onClick, ...rest}) => {
  return (
    <BlueButton
      {...rest}
      onClick={() => type === 'button' && !loading && onClick()}
      style={white?{
        backgroundColor: COLORS.white,
        color: COLORS.darkBlue
      }: null}
      type={type}
    >
      {loading ? (
        <CircularProgress
          size={14}
          style={{color: white?COLORS.darkBlue:'white'}}
        />
      ): children}
    </BlueButton>
  )};

SubmitButton.defaultProps = {
  loading: false,
  type: 'submit',
  white: false,
};

SubmitButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button']),
  white: PropTypes.bool
};

export default SubmitButton;
