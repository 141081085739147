import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar, Backdrop, Box, Card, CardContent, CardHeader,
  Dialog, DialogContent, Fade, Icon, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

const MemberModal = ({className, open, handleClose, member}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          classes: {
            root: classes.backdrop,
          }
        }}
        PaperComponent={'div'}
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        closeAfterTransition
        fullWidth
        maxWidth={'md'}
        onClose={handleClose}
        open={open}
        scroll={'body'}
      >
        <DialogContent
          className={classes.main}
          classes={{ root: classes.dialogContent}}
        >
          <Fade in={open}>
            <Box className={classes.cardBox}>
              <Card
                className={classes.cardRoot}
                elevation={12}
              >
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={handleClose}
                    >
                      <Icon
                        className={classes.close}
                        fontSize="large"
                      >
                        close
                      </Icon>
                    </IconButton>
                  }
                />
                <CardContent className={classes.content}>
                  <Typography
                    className={classes.fullName}
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                  >
                    {member.fullName}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                  >
                    {member.role}
                  </Typography>
                  <Typography
                    className={classes.description}
                    color="textSecondary"
                    variant="h5"
                  >
                    {member.description}
                  </Typography>
                </CardContent>
              </Card>
              <Avatar
                alt={member.fullName}
                className={classes.photo}
                src={member.photo}
              />
            </Box>
          </Fade>
        </DialogContent>
      </Dialog>
    </div>
  );
};

MemberModal.defaultProps = {
  className: null,
  open: false,
};

MemberModal.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  member: PropTypes.shape({
    fullName: PropTypes.string,
    photo: PropTypes.string,
    role: PropTypes.string,
    description: PropTypes.string,
  }),
  open: PropTypes.bool,
};

export default MemberModal;
