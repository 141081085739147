import { makeStyles } from '@material-ui/core';
import { COLORS } from 'config/constants';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '50px',
    width: 452,
    height: 60,
    [theme.breakpoints.up('xl')]: {
      width: 586,
      height: 72
    }
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignSelf: 'flex-start',
    transition: 'width 0.3s, opacity 0.3s ease-in',
    borderRadius: 50,
    padding: '15px 0px 15px 20px',
    backgroundColor: COLORS.white,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.4,
    lineHeight: 0.7,
    color: COLORS.black,
    boxShadow: '0px 2px 4px 0px #00000040',
    [theme.breakpoints.up('xl')]: {
      fontSize: 25,
      letterSpacing: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '&::placeholder': {
      color: COLORS.grey1
    },
    '& input': {
      paddingRight: '109px'
    }
  },
  show: {
    '&$input': {
      opacity: 1
    }
  },
  hide: {
    width: 143,
    opacity: 0
  },
  button: {
    width: 109,
    height: 60,
    boxShadow: 'none',
    alignSelf: 'flex-end',
    [theme.breakpoints.up('xl')]: {
      width: 129,
      height: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 89,
      height: 48
    }
  },
  buttonFinal: {
    '&$button': {
      width: 143,
      height: 60,
      opacity: 1,
      position: 'absolute',
      boxShadow: '0px 2px 4px 0px #00000040',
      transform: 'translateX(-308px)',
      transition: 'transform 0.3s ease-in',
      [theme.breakpoints.up('xl')]: {
        width: 268,
        height: 72
      },
      '& span': {
        width: '186px',
        position: 'absolute',
        [theme.breakpoints.up('xl')]: {
          width: 188
        }
      }
    }
  }
}));
