import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField as MuiTextField,
  makeStyles,
  InputBase,
  Box
} from '@material-ui/core';
import styles from '../styles';

const useStyles = makeStyles(styles);

const TextFieldWrapper = ({
  input: { name, onChange, value, type = 'text', ...restInput },
  meta,
  mui,
  label,
  ...rest
}) => {
  const { helperText, ...lessRest } = rest;
  const classes = useStyles();

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  if (mui) {
    return (
      <MuiTextField
        error={showError}
        fullWidth
        helperText={showError ? meta.error || meta.submitError : helperText}
        inputProps={restInput}
        label={label}
        margin="normal"
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        {...lessRest}
      />
    );
  } else {
    return (
      <FormControl
        error={showError}
        fullWidth
      >
        {label && (
          <Box
            className={classes.label}
            component={'label'}
            htmlFor={name}
          >
            {label}
          </Box>
        )}
        <InputBase
          className={classes.inputBase}
          inputProps={restInput}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
          {...lessRest}
        />
        <FormHelperText className={classes.helperText}>
          {showError ? meta.error || meta.submitError : helperText}
        </FormHelperText>
      </FormControl>
    );
  }
};

TextFieldWrapper.defaultProps = {
  input: {},
  label: '',
  meta: {},
  mui: false
};

TextFieldWrapper.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  meta: PropTypes.any,
  mui: PropTypes.bool
};

export default TextFieldWrapper;
