import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Form } from 'react-final-form';
import { CustomButton, TextField } from 'components';
import { ErrorHelper, RequestHelper } from 'utils';
import { PromoEmailApi } from 'api';
import { useDispatch } from 'react-redux';
import { MessageActions } from 'store/actions';

const useStyles = makeStyles(() => ({
  btn: {
    width: '25%',
    maxWidth: 200,
    minWidth: 120,
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  root: {
    width: '100%'
  },
}));

const FlyerForm = props => {
  const classes = useStyles();
  const requestHelper = new RequestHelper();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (v) => {
    setLoading(true);

    requestHelper
      .registerRequest(PromoEmailApi.createOne(v))
      .then((res) => {
        console.log('---res', res.data);
        dispatch(MessageActions.showMessage({
          title: 'Flyers En-Route',
          content: 'Thank you for signing up for the FinTron Flyer! Keep an eye out for our daily email. For just 3-minutes a day we\'ll give you the path to financial freedom!',
          action: 'Close'
        }));
      })
      .catch((err) => {
        dispatch(MessageActions.showMessage({
          title: 'Failed',
          content: ErrorHelper.getErrString(err),
          action: 'Close'
        }));
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <div className={clsx(classes.root, props.className)}>
      <Form
        initialValues={null}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <Box padding={1}>
              <TextField
                name="email"
                placeholder="Email Address"
                required
                type="email"
              />
            </Box>
            <Box className={classes.btn}>
              <CustomButton
                loading={loading} 
                title={(
                  <>
                    <span>Sign Up </span>
                    <ChevronRightIcon fontSize="small"/>
                  </>
                )}
                type="submit"
              />
            </Box>
          </form>
        )}
      />
    </div>
  );
};

FlyerForm.defaultProps = {
  className: null
};

FlyerForm.propTypes = {
  className: PropTypes.string
};

export default FlyerForm;
