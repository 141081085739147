import { RESTS } from 'config/constants';
import axios from './axios'

const PromoEmail = {
  // eslint-disable-next-line no-undef
  createOne: (data) => axios.post(`${RESTS.EDU_SITE}/api/promo-emails/`, {
    data,
    options: { disableAuthorization: true }
  })
}

export default PromoEmail;
