import { COLORS } from 'config/constants';

const pageStyles = (theme) => ({
  root: {},
  blueWrap: {
    backgroundColor: COLORS.darkBlue,
    minHeight: '100vh',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  blueWrapSmall: {
    backgroundColor: COLORS.darkBlue,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(30, 0, 7)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(22, 0, 5)
    }
  },
  banner: {
    color: COLORS.white,
    fontWeight: 300,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 48
    }
  },
  subBanner: {
    marginTop: theme.spacing(2),
    color: COLORS.white,
    fontWeight: 200,
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  topWrap: {
    maxWidth: '900px'
  },
  contestList: {
    marginTop: theme.spacing(8)
  },
  back: {
    margin: theme.spacing(3, 0, 4)
  },
  green: {
    color: COLORS.green
  },
  red: {
    color: COLORS.red
  },
  modalButtonContainer: {
    width: 150,
    margin: 'auto'
  },
  primaryGradient: {
    background: 'linear-gradient(130deg, #26A8DF 0%, #F719FF 100%)'
  }
});

export default pageStyles;
