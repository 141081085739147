export default {
  NON_FIELD_ERRORS: 'non_field_errors',
  // eslint-disable-next-line no-undef
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  // eslint-disable-next-line no-undef
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  // eslint-disable-next-line no-undef
  EDU_SITE: process.env.REACT_APP_EDU_SITE,
  // eslint-disable-next-line no-undef
  CMS_API_HOST: process.env.REACT_APP_CMS_API_HOST
}
