import axios from './axios'

export default {
  verify: (data) => axios.post('/api/auth/verify-email/', {data}),

  sendVerificationEmail: (data) => axios.post('/api/auth/send-verification-email/', {data}),

  unlockAccount: (data) => axios.post('/api/auth/unlock/', { data }),

  sendUnlockEmail: (data) => axios.post('/api/auth/send-unlock-email/', { data })
}
