import { colors } from '@material-ui/core';
import { FONTS, COLORS } from 'config/constants';

const styles = (theme) => ({
  label: {
    color: COLORS.black,
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.36,
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
      lineHeight: 1.3,
      letterSpacing: 0.44
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: 0.28
    }
  },
  inputBase: {
    backgroundColor: COLORS.white,
    borderRadius: 6,
    boxShadow: '0px 2px 4px #00000040',
    color: COLORS.black,
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.36,
    marginTop: 12,
    '& .MuiInputBase-input': {
      padding: '8px 0 9px 15px'
    },
    '& ::placeholder': {
      color: COLORS.grey2,
      opacity: 1
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
      lineHeight: 1.3,
      letterSpacing: 0.44,
      marginTop: 17
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: 0.28,
      marginTop: 7
    }
  },
  helperText: {
    color: colors.red[600],
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    [theme.breakpoints.up('xl')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    }
  },
  selectInput: {
    backgroundColor: COLORS.white,
    borderRadius: 6,
    boxShadow: '0px 2px 4px #00000040',
    color: COLORS.black,
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.36,
    marginTop: 12,
    '& .MuiSelect-select': {
      padding: '8px 0 9px 15px'
    },
    '& .MuiSelect-icon': {
      margin: '8px 15px 0px 0px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
      lineHeight: 1.3,
      letterSpacing: 0.44,
      marginTop: 17
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: 0.28,
      marginTop: 7
    }
  },
  selectInputPlaceholder: {
    color: COLORS.grey2,
    opacity: 1
  }
});

export default styles;
