import React  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Modal from 'components/Modal';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '85vw',
    maxWidth: 800,
    height: '80vh',
    display: 'flex',
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(3)
    }
  },
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  }
}));

const HtmlModal = ({ isUri, source, ...rest}) => {
  const classes = useStyles();

  return (
    <Modal
      body={
        (
          <iframe
            frameBorder={0}
            height="100%"
            src={isUri ? source : undefined}
            srcDoc={!isUri ? source : undefined}
            title="modal-iframe"
            width="100%"
          />
        )
      }
      classes={{
        wrap: classes.wrap,
        body: classes.body,
      }}
      maxWidth={'xl'}
      {...rest}
    />
  );
};

HtmlModal.propTypes = {
  isUri: PropTypes.bool,
  source: PropTypes.string,
};

export default HtmlModal;
