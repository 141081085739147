import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import message from './message/reducers';
import session from './session/reducers';
import preReg from './preReg';

export { creators as PreRegActions } from './preReg';

export default combineReducers({
  routing: routerReducer,
  message,
  session,
  preReg
});
