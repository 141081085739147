import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export default function configureStore(history = {}, preloadedState = {}) {
  const routeMiddleware = routerMiddleware(history);
  const store = createStore(
    rootReducer,
    preloadedState,
    bindMiddleware([sagaMiddleware, routeMiddleware])
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
