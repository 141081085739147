import { handleActions } from 'redux-actions';

const initState = {
  state: false,
  options: {
    autoHideDuration: 6000,
    title: '',
    content: 'Hi',
    action: 'Close',
    variant: null
  }
};

export default handleActions(
  {
    SHOW_MESSAGE: (state, action) => {
      return {
        state: true,
        options: {
          ...initState.options,
          ...action.payload
        }
      };
    },
    HIDE_MESSAGE: (state) => {
      return {
        ...state,
        state: false
      };
    }
  },
  initState
);
