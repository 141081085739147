import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GetTheAppButton, MagicContainer } from 'components';
import {
  Box,
  Grid,
  isWidthDown,
  Typography,
  withWidth
} from '@material-ui/core';
import data from './data';
import SectionSocials from './SectionSocials';
import SectionSponsors from './SectionSponsors';
import { GLOBALS } from 'config/constants';
import { LogoTextWhite } from 'assets/images/svgs';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MessageActions } from 'store/actions';
import { PreRegActions } from 'store/reducers';
import { useStyles } from './styles';
import { useInView } from 'react-intersection-observer';

const Footer = ({ width, footerRootClass }) => {
  const [showInput, setShowInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ preReg }) => preReg);
  const classes = useStyles();
  const isMobile = isWidthDown('sm', width);
  const { footerAction, footerLinks, footerText } = data;
  const { ref, inView } = useInView({
    threshold: 0.1
  });

  const onShowMessage = ({ title, content, action }) =>
    dispatch(MessageActions.showMessage({ title, content, action }));

  const onRegisterUser = (phoneNumber) =>
    dispatch(
      PreRegActions.getAppRequest({
        phoneNumber,
        successCb: () => {
          setPhoneNumber('');
          setShowInput(false);
          setShowSuccess(true);
        }
      })
    );

  const onCustomButtonClick = () =>
    isMobile
      ? window.open(GLOBALS.APP_STORE_LINK, '_self')
      : setShowInput(true);

  const location = useLocation();

  return (
    <Box className={clsx(classes.footerRoot, footerRootClass)}>
      <MagicContainer
        mainStyle={clsx(
          location.pathname === '/consumer' ? classes.ssMain : classes.ssB2B
        )}
      >
        <SectionSocials />
      </MagicContainer>
      <MagicContainer mainStyle={classes.spMain}>
        <SectionSponsors />
      </MagicContainer>
      <Grid className={clsx(classes.footerContainer)} container ref={ref}>
        <Grid
          className={clsx(classes.footer, inView && classes.inView)}
          container
          item
          xs={12}
          md={12}
        >
          <Grid className={classes.logoAndButtonContainer} item xs={12} md={4}>
            <Link to={'/home'}>
              <LogoTextWhite className={classes.logo} />
            </Link>
            <GetTheAppButton
              containerStyle={classes.customPhoneInput}
              value={phoneNumber}
              setValue={setPhoneNumber}
              showInput={showInput}
              leftToRight
              autoFocus={false}
              onBlur={phoneNumber ? () => {} : () => setShowInput(false)}
              initialTitle={footerAction}
              onInitialButtonClick={onCustomButtonClick}
              onButtonClick={onRegisterUser}
              isLoading={isLoading}
              showError={onShowMessage}
              showSuccess={showSuccess}
            />
          </Grid>
          <Grid className={classes.spacing} item md={4} />
          <Grid className={classes.footerLinksContainer} item xs={12} md={4}>
            {footerLinks.map(({ text, link, target }) => (
              <Typography
                key={text}
                className={clsx(classes.linkText, classes.linkTextMargin)}
                onClick={() => window.open(link, target)}
              >
                {text}
              </Typography>
            ))}
          </Grid>
          <Grid className={classes.footerTextContainer} item xs={12} md={12}>
            {footerText.map((text, idx) => (
              <Box key={idx}>
                <Typography className={classes.footerText}>{text}</Typography>
                {idx !== 3 && <Box component="br" />}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  width: PropTypes.string,
  footerRootClass: PropTypes.string
};

export default withWidth()(Footer);
