import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import styles from './styles';
import { Typography } from '@material-ui/core';
import FImage from 'components/FImage';

const useStyles = makeStyles(styles);

const MemberItem = ({ className, fullName, photo, role, onClick}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className, 'debug--container')}
      onClick={onClick}
    >
      <div className={classes.avatarWrap}>
        <FImage
          alt={fullName}
          className={classes.avatar}
          src={photo}
        />
      </div>
      <Typography 
        className={classes.fullName}
        gutterBottom
        variant={'h4'}
      >
        {fullName}
      </Typography>
      <Typography 
        className={classes.role}
        variant={'h6'}
      >
        {role}
      </Typography>
    </div>
  );
};

MemberItem.defaultProps = {
  className: null
};

MemberItem.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  onClick: PropTypes.func,
  photo: PropTypes.string,
  role: PropTypes.string,
};

export default MemberItem;
