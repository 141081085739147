import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  isWidthDown,
  Toolbar,
  withWidth
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { COLORS, GLOBALS } from 'config/constants';
import Menu from './data';
import { DESIGNS } from 'config/constants';
import { BgHeaderSticky, BgHeaderMobile } from 'assets/images/pngs';
import { LogoTextWhite, LogoWhite } from 'assets/images/svgs';

const { pageSpacing } = DESIGNS;

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 5,
    height: '62px',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideTop: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginBottom: -62
    }
  },
  toolbar: {
    height: '100%',
    backgroundImage: `url(${BgHeaderSticky})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(2, pageSpacing.md),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${BgHeaderMobile})`,
      paddingRight: theme.spacing(pageSpacing.xs),
      paddingLeft: theme.spacing(pageSpacing.xs)
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingRight: theme.spacing(pageSpacing.sm),
      paddingLeft: theme.spacing(pageSpacing.sm)
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingRight: theme.spacing(pageSpacing.md),
      paddingLeft: theme.spacing(pageSpacing.md)
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: 139,
      paddingLeft: 139
    }
  },
  logoContainer: {
    width: 90,
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 100
    },
    [theme.breakpoints.up('lg')]: {
      width: 114
    },
    [theme.breakpoints.down('sm')]: {
      width: 30
    }
  },
  grow: {
    flexGrow: 1
  },
  menuBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  menuButton: {
    color: COLORS.white,
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 'bold',
    letterSpacing: 1.2,
    marginLeft: 35,
    padding: '6px',
    borderRadius: 0,
    textTransform: 'uppercase',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: 2,
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 'bold'
    }
  },
  activeMenu: {
    '&::before': {
      transform: 'translateX(-50%)',
      borderRadius: '100%',
      position: 'absolute',
      background: COLORS.white,
      bottom: '-5px',
      height: '6px',
      width: '6px',
      content: '""',
      left: '50%'
    }
  },
  signupButton: {
    borderRadius: 50,
    backgroundColor: COLORS.white,
    color: COLORS.fintronBlue,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    '&:hover': {
      backgroundColor: COLORS.fintronBlue,
      color: COLORS.white
    }
  },
  hamburgerIcon: {
    display: 'flex',
    color: COLORS.fintronBlue,
    fontSize: 22,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const StickyHeader = ({ path, width }) => {
  const classes = useStyles();
  const isMobile = isWidthDown('sm', width);
  const location = useLocation();
  return (
    <>
      {/* Hides sticky menu when page is scrolled to top - better view on iniitial load */}
      <Box className={classes.hideTop} />
      <Box className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoContainer}>
            <Link to="/home">
              {isMobile ? (
                <LogoWhite color={COLORS.white} />
              ) : (
                <LogoTextWhite color={COLORS.white} />
              )}
            </Link>
          </Box>
          <Box className={classes.grow} />
          <Box className={classes.menuBar}>
            {Menu.menuItems.map((menu, index) => {
              if (
                menu.title === 'Sign Up' &&
                location.pathname == menu.location
              ) {
                return (
                  <Button
                    key={index}
                    className={clsx(
                      classes[menu.className],
                      classes.signupButton
                    )}
                    onClick={() => window.open(GLOBALS.APP_STORE_LINK, '_self')}
                  >
                    {menu.title}
                  </Button>
                );
              } else if (
                (menu.title === 'Developer Docs' &&
                  location.pathname === '/home') ||
                (menu.title === 'Developer Docs' &&
                  location.pathname === '/contact-sales')
              ) {
                return (
                  <a
                    key={index}
                    // eslint-disable-next-line no-undef
                    href={process.env.REACT_APP_API_DOCS_LINK}
                    rel="noopener noreferer"
                  >
                    <Button className={clsx(classes[menu.className])}>
                      {menu.title}
                    </Button>
                  </a>
                );
              } else if (
                menu.title === 'Contact Sales' &&
                location.pathname == menu.location
              ) {
                return (
                  <Link key={index} to="/contact-sales">
                    <Button
                      className={clsx(
                        classes[menu.className],
                        classes.signupButton
                      )}
                    >
                      {menu.title}
                    </Button>
                  </Link>
                );
              } else if (
                menu.title !== 'Contact Sales' &&
                location.pathname == menu.location
              ) {
                return (
                  <Link key={index} to={menu.redirect}>
                    <Button
                      className={clsx(
                        classes[menu.className],
                        path === menu.redirect && classes.activeMenu
                      )}
                    >
                      {menu.title}
                    </Button>
                  </Link>
                );
              } else if (
                (menu.title !== 'Developer Docs' &&
                  location.pathname == '/contact-us') ||
                (location.pathname == '/contact-sales' &&
                  menu.redirect !== location.pathname)
              ) {
                return (
                  <Link key={index} to={menu.redirect}>
                    <Button
                      className={clsx(
                        classes[menu.className],
                        menu.title === 'Sign Up' && classes.signupButton
                      )}
                    >
                      {menu.title}
                    </Button>
                  </Link>
                );
              }
            })}
          </Box>
        </Toolbar>
      </Box>
    </>
  );
};

StickyHeader.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string,
  isTransparent: PropTypes.bool
};

export default withWidth()(StickyHeader);
