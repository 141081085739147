import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

const styles = {
  grid: {}
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <Grid
      item
      {...rest}
      className={clsx(classes.grid, className, {
        'debug--grid-item': props.debug
      })}
    >
      {children}
    </Grid>
  );
}

GridItem.defaultProps = {
  className: ''
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  debug: PropTypes.bool
};
