import React from 'react';
import { Box, Input } from '@material-ui/core';
import clsx from 'clsx';
import CustomButton from 'components/Button/CustomButton';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

const GetReferralButton = ({
  containerStyle,
  inputStyle,
  showInput,
  value,
  setValue,
  autoFocus,
  onBlur,
  placeholder,
  inputType,
  fullWidth,
  disableUnderline,
  autoComplete,
  initialTitle,
  title,
  buttonType,
  onButtonClick,
  isLoading
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, containerStyle)}>
      <Input
        className={clsx(
          classes.input,
          showInput ? classes.show : classes.hide,
          inputStyle
        )}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        autoFocus={autoFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        type={inputType}
        fullWidth={fullWidth}
        disableUnderline={disableUnderline}
        autoComplete={autoComplete}
      />
      <CustomButton
        buttonStyle={clsx(classes.button, !showInput && classes.buttonFinal)}
        title={showInput ? initialTitle : title}
        type={buttonType}
        onClick={
          buttonType === 'submit' && showInput ? () => {} : onButtonClick
        }
        loading={isLoading}
        rounded
      />
    </Box>
  );
};

GetReferralButton.defaultProps = {
  containerStyle: '',
  inputStyle: '',
  showInput: true,
  value: '',
  setValue: () => {},
  autoFocus: false,
  onBlur: () => {},
  placeholder: 'Enter Email',
  inputType: 'email',
  fullWidth: true,
  disableUnderline: true,
  autoComplete: 'off',
  initialTitle: 'ENTER',
  title: 'SENT!',
  buttonType: 'submit',
  onButtonClick: () => {},
  isLoading: false
};

GetReferralButton.propTypes = {
  containerStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  showInput: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  fullWidth: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  autoComplete: PropTypes.string,
  initialTitle: PropTypes.string,
  title: PropTypes.string,
  buttonType: PropTypes.string,
  onButtonClick: PropTypes.func,
  isLoading: PropTypes.bool
};

export default GetReferralButton;
