const AVATAR_SIZE = 160;

const styles = theme => ({
  root: {
    padding: theme.spacing(5, 0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0)
    }
  },
  avatar: {
    borderRadius: AVATAR_SIZE/2,    
  },
  avatarWrap: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE/2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: AVATAR_SIZE * 0.6,
      height: AVATAR_SIZE * 0.6,
      borderRadius: AVATAR_SIZE * 0.3
    }
  },
  fullName: {
    fontWeight: 700,
    fontSize: 22,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  role: {
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11
    }
  }
});

export default styles
