import { makeStyles } from '@material-ui/styles';
import { COLORS } from 'config/constants';
import data from './data';

export const useStyles = makeStyles((theme) => ({
  footerRoot: {
    position: 'relative'
  },
  ssMain: {
    backgroundImage: `url(${data.bgSocialSection})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    paddingLeft: 252,
    paddingRight: 254,
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 460,
      paddingRight: 464
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${data.bgSocialSectionMobile})`,
      paddingLeft: 44,
      paddingRight: 44
    }
  },
  ssB2B: {
    backgroundImage: `url(${data.bgSocialSectionB2B})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    paddingLeft: 252,
    paddingRight: 254,
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 460,
      paddingRight: 464
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${data.bgSocialSectionB2BMobile})`,
      paddingLeft: 44,
      paddingRight: 44
    }
  },
  ssContainer: {
    justifyContent: 'space-between',
    padding: '65px 0px',
    [theme.breakpoints.up('xl')]: {
      padding: '79px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '51px 0px'
    }
  },
  ssIconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  ssIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 'auto',
      '& span svg': {
        width: 22,
        height: 22
      }
    },
    '&:hover': {
      background: 'none',
      transform: 'scale(1.15)',
      transition: 'transform 1s ease-in-out'
    }
  },
  spMain: {
    padding: '0px 115px',
    [theme.breakpoints.up('xl')]: {
      padding: '0px 190px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px'
    }
  },
  spContainer: {
    paddingTop: 85,
    paddingBottom: 114,
    justifyContent: 'center',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 92,
      paddingBottom: 138
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 78,
      paddingBottom: 90,
      justifyContent: 'space-evenly'
    }
  },
  spIconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  spIconContainerMarginTop: {
    marginTop: 30,
    [theme.breakpoints.up('xl')]: {
      marginTop: 41
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 19
    }
  },
  spIcon: {
    cursor: 'default',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 0,
    maxWidth: '100%',
    '&:hover': {
      background: 'none',
      transform: 'scale(1.01)',
      transition: 'transform 1s ease-in-out'
    }
  },
  spIconMobile: {
    [theme.breakpoints.only('xs')]: {
      width: 150,
      height: 39
    }
  },
  footerContainer: {
    height: 'auto',
    backgroundColor: COLORS.black,
    overflow: 'hidden',
    padding: theme.spacing(0, 17),
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0, 5)
    },
    [theme.breakpoints.down('sm')]: {
      height: 888,
      padding: theme.spacing(0, 2),
      overflow: 'inherit'
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'inherit'
    }
  },
  footer: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      top: 0,
      '& div :not($button)': { opacity: 0.1, transition: 'opacity 1s ease-in' }
    },
    position: 'relative',
    transition: 'top 1s ease-in, height 1s ease-in',
    backgroundImage: `url(${data.bgFooterSection})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(11, 12),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(15, 16)
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${data.bgFooterSectionMobile})`,
      padding: theme.spacing(0, 1),
      position: 'relative',
      top: -30,
      filter: 'blur(0px)',
      height: 'calc(100% + 30px)'
    }
  },
  inView: {
    '&$footer': {
      [theme.breakpoints.up('md')]: {
        top: -30,
        height: 'calc(100% + 30px)',
        '& div :not($button)': {
          opacity: 1,
          transition: 'transform 0.3s ease-out, opacity 1s ease-in'
        }
      }
    }
  },
  logoAndButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 30,
      marginBottom: 14
    }
  },
  logo: {
    color: COLORS.white,
    width: 260,
    height: 103,
    [theme.breakpoints.up('xl')]: {
      width: 300,
      height: 143
    },
    [theme.breakpoints.down('sm')]: {
      width: 208,
      height: 82
    }
  },
  customPhoneInput: {
    marginTop: 135,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 32
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: 200
    }
  },
  button: {
    width: 186,
    height: 60,
    marginTop: 48,
    opacity: 0.6,
    boxShadow: '0px 2px 4px 0px #707070',
    [theme.breakpoints.up('xl')]: {
      width: 209,
      height: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 153,
      height: 38,
      fontSize: 16,
      marginTop: 10
    },
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  spacing: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  footerLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 28,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 65px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  linkText: {
    textDecoration: 'underline',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 900,
    color: COLORS.white,
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      fontSize: 17
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: 1.25
    }
  },
  linkTextMargin: {
    marginBottom: 28,
    [theme.breakpoints.down('sm')]: {
      margin: '14px 0'
    }
  },
  footerTextContainer: {
    paddingTop: 60,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 70
    },
    [theme.breakpoints.down('sm')]: {
      padding: '14px 26px 20px 26px'
    }
  },
  footerText: {
    fontSize: 15,
    fontWeight: 300,
    color: COLORS.white,
    textAlign: 'center',
    lineHeight: 1.33,
    '& a': {
      color: COLORS.white,
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: 1.25
    }
  }
}));
