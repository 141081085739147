import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Button } from '@material-ui/core';
import { COLORS } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.fintronBlue,
    color: COLORS.white,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    letterSpacing: 2,
    lineHeight: 0.7,
    textTransform: 'uppercase',
    boxShadow: '0px 3px 3px 0px #00000026',
    [theme.breakpoints.up('xl')]: {
      fontSize: 26,
      letterSpacing: 2.4,
      lineHeight: 0.8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      letterSpacing: 1.6,
      lineHeight: 0.6
    },
    '&:hover': {
      backgroundColor: COLORS.fintronBlue,
      opacity: 1
    }
  },
  rounded: {
    borderRadius: 50
  },
  inversedStyle: {
    backgroundColor: COLORS.white,
    color: COLORS.fintronBlue,
    '&:hover': {
      backgroundColor: COLORS.white,
      color: COLORS.fintronBlue
    }
  },
  loading: {
    color: COLORS.white
  },
  inversedLoadingStyle: {
    color: COLORS.fintronBlue
  }
}));

const CustomButton = ({
  buttonStyle,
  rounded,
  inverseTheme,
  type,
  disabled,
  loading,
  onClick,
  circularProgressSize,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(
        classes.root,
        buttonStyle,
        rounded && classes.rounded,
        inverseTheme && classes.inversedStyle
      )}
      disabled={disabled}
      onClick={loading ? () => {} : onClick}
      type={type}
      {...rest}
    >
      {loading ? (
        <CircularProgress
          className={clsx(
            classes.loading,
            inverseTheme && classes.inversedLoadingStyle
          )}
          size={circularProgressSize}
        />
      ) : (
        title
      )}
    </Button>
  );
};

CustomButton.defaultProps = {
  buttonStyle: '',
  rounded: false,
  inverseTheme: false,
  type: 'button',
  disabled: false,
  loading: false,
  onClick: () => {},
  circularProgressSize: 25,
  title: 'Custom Button'
};

CustomButton.propTypes = {
  buttonStyle: PropTypes.string,
  circularProgressSize: PropTypes.number,
  disabled: PropTypes.bool,
  inverseTheme: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string
};

export default CustomButton;
