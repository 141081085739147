import React, { useEffect } from 'react';
import { Box, Input, InputAdornment, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { COLORS } from 'config/constants';
import CustomButton from 'components/Button/CustomButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 452,
    height: 60,
    [theme.breakpoints.up('xl')]: {
      width: 586,
      height: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 317,
      height: 48
    }
  },
  input: {
    width: '100%',
    height: '100%',
    borderRadius: 50,
    padding: '15px 0px 15px 20px',
    backgroundColor: COLORS.white,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0.4,
    lineHeight: 0.7,
    color: COLORS.black,
    boxShadow: '0px 2px 4px 0px #00000040',
    [theme.breakpoints.up('xl')]: {
      fontSize: 25,
      letterSpacing: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      letterSpacing: 0.3
    },
    '&::placeholder': {
      color: COLORS.grey1
    }
  },
  button: {
    width: 109,
    height: 60,
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
      width: 129,
      height: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 89,
      height: 48
    }
  }
}));

const CustomEmailInput = ({
  containerStyle,
  inputStyle,
  value,
  setValue,
  autoFocus,
  onBlur,
  placeholder,
  inputType,
  fullWidth,
  disableUnderline,
  autoComplete,
  title,
  buttonType,
  onButtonClick,
  isLoading
}) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      setValue('');
    };
  }, []);

  return (
    <Box className={clsx(classes.container, containerStyle)}>
      <Input
        className={clsx(classes.input, inputStyle)}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
        autoFocus={autoFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        type={inputType}
        fullWidth={fullWidth}
        disableUnderline={disableUnderline}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="end">
            <CustomButton
              buttonStyle={classes.button}
              title={title}
              type={buttonType}
              onClick={buttonType === 'submit' ? () => {} : onButtonClick}
              loading={isLoading}
              rounded
            />
          </InputAdornment>
        }
      />
    </Box>
  );
};

CustomEmailInput.defaultProps = {
  containerStyle: '',
  inputStyle: '',
  value: '',
  setValue: () => {},
  autoFocus: false,
  onBlur: () => {},
  placeholder: 'Enter Email',
  inputType: 'email',
  fullWidth: true,
  disableUnderline: true,
  autoComplete: 'off',
  title: 'ENTER',
  buttonType: 'submit',
  onButtonClick: () => {},
  isLoading: false
};

CustomEmailInput.propTypes = {
  containerStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  fullWidth: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  autoComplete: PropTypes.string,
  title: PropTypes.string,
  buttonType: PropTypes.string,
  onButtonClick: PropTypes.func,
  isLoading: PropTypes.bool
};

export default CustomEmailInput;
