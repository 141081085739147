import palette from './palette';
import { FONTS } from 'config/constants';

export default {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontWeightExtraBold: 900,
  h1: {
    fontFamily: FONTS.Oswald,
    color: palette.white,
    fontWeight: 200
  },
  h2: {
    fontFamily: FONTS.Oswald,
    color: palette.text.primary,
    fontWeight: 300
  },
  h3: {
    fontFamily: FONTS.Oswald,
    color: palette.text.primary,
    fontWeight: 400
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: 1.2
  },
  h5: {
    fontFamily: FONTS.SourceSansPro,
    color: palette.text.primary,
    fontWeight: 300,
    lineHeight: 1.25
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px'
  },
  subtitle1: {
    fontFamily: FONTS.SourceSansPro,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '23px'
  },
  subtitle2: {
    fontFamily: FONTS.SourceSansPro,
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 1.64
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    lineHeight: '21px'
  },
  body2: {
    fontFamily: FONTS.SourceSansPro,
    fontWeight: 300,
    lineHeight: 1.2
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '13px',
    textTransform: 'uppercase'
  }
};
