/* eslint-disable indent */
import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, LinearProgress } from '@material-ui/core';
import { ScrollableHeader, StickyHeader } from './components';
import { AuthGuard, Modal, CustomButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { MessageActions } from 'store/actions';

const useStyles = makeStyles((theme) => ({
  routesContainer: {
    marginTop: (props) => (props.isTransparent ? -115 : 0),
    [theme.breakpoints.down('sm')]: {
      marginTop: (props) => (props.isTransparent ? -90 : 0)
    }
  },
  modalButtonContainer: {
    width: 150,
    margin: 'auto',
    marginTop: theme.spacing(3)
  }
}));

const MainLayout = ({ location, route }) => {
  const dispatch = useDispatch();
  const modal = useSelector(({ message }) => message);
  const isTransparent = ['/home', '/learn', '/investing', '/consumer'].includes(
    location.pathname
  );
  const classes = useStyles({ isTransparent });
  const modalClose = () => {
    dispatch(MessageActions.hideMessage());
  };

  return (
    <Box>
      <StickyHeader path={location.pathname} isTransparent={isTransparent} />
      {location.pathname === '/contact-sales' ||
      location.pathname === '/contact-us' ? null : (
        <ScrollableHeader
          path={location.pathname}
          isTransparent={isTransparent}
        />
      )}
      <Box className={classes.routesContainer}>
        <Suspense fallback={<LinearProgress color={'secondary'} />}>
          <AuthGuard auth={route.auth}>{renderRoutes(route.routes)}</AuthGuard>
        </Suspense>
      </Box>
      <Modal
        open={modal.state}
        onClose={modalClose}
        title={modal.options.title}
        body={modal.options.content}
        footer={
          <Box className={classes.modalButtonContainer}>
            <CustomButton
              onClick={modalClose}
              rounded
              title={modal.options.action}
            />
          </Box>
        }
      />
    </Box>
  );
};

MainLayout.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default MainLayout;
