
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(15, 0, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(28, 0, 15)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(15, 0, 10)
    },
    position: 'relative',
  },
  logoWrapper: {
    position: 'absolute',
    top: '-25%',
    bottom: 0,
    right: 0,
    left: 0,
  },
  transparentLogo: {
    width: '130%',
    maxWidth: 1600
  },
  gradient: {
    background: 'linear-gradient(130deg, #26A8DF 0%, #F719FF 100%)'
  }
});

export default styles
