import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import EmptyLayout from './layouts/Empty';
import MainLayout from './layouts/MainLayout';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    path: '/referral',
    component: MainLayout,
    routes: [
      {
        path: '/referral/:code',
        component: lazy(() => import('./views/Referral'))
      }
    ]
  },
  {
    path: '/errors',
    component: EmptyLayout,
    routes: [
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('./views/Error404'))
      }
    ]
  },
  {
    path: '*',
    component: MainLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('views/B2B'))
      },
      {
        path: '/disclosures',
        exact: true,
        component: lazy(() => import('views/Disclosures'))
      },
      {
        path: '/consumer',
        exact: true,
        component: lazy(() => import('views/Consumer'))
      },
      // {
      //   path: '/investing',
      //   exact: true,
      //   component: lazy(() => import('views/Investing'))
      // },
      // {
      //   path: '/learn',
      //   exact: true,
      //   component: lazy(() => import('views/Learn'))
      // },
      // {
      //   path: '/login',
      //   exact: true,
      //   component: lazy(() => import('views/Login'))
      // },
      // {
      //   path: '/signup',
      //   exact: true,
      //   component: lazy(() => import('views/Signup'))
      // },
      // {
      //   path: '/presignup',
      //   exact: true,
      //   component: lazy(() => import('views/SignupViaApp'))
      // },
      {
        path: '/contact-us',
        exact: true,
        component: lazy(() => import('views/ContactUs'))
      },
      {
        path: '/contact-sales',
        exact: true,
        component: lazy(() => import('views/ContactSales'))
      },
      {
        path: '/verify',
        exact: true,
        component: lazy(() => import('views/Verify'))
      },
      {
        path: '/unlock',
        exact: true,
        component: lazy(() => import('views/Unlock'))
      },
      // {
      //   path: '/press',
      //   exact: true,
      //   component: lazy(() => import('views/Press'))
      // },
      // {
      //   path: '/fees',
      //   exact: true,
      //   component: () => <Redirect to="/home" />
      // },
      // {
      //   path: '/development',
      //   exact: true,
      //   component: lazy(() => import('views/Development'))
      // },
      // {
      //   path: '/fake',
      //   exact: true,
      //   component: lazy(() => import('views/FakeView'))
      // },
      {
        path: '/*',
        exact: true,
        component: () => <Redirect to="/home" />
      }
    ]
  }
];

export default routes;
