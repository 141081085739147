import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import overrides from './overrides';
import { DESIGNS } from 'config/constants';

const theme = createTheme({
  spacing: DESIGNS.themeSpacing,
  palette,
  typography,
  breakpoints,
  overrides
});

export default theme;
