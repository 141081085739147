import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { DESIGN_UTILS } from 'utils';
import clsx from 'clsx';

const styles = {
  grid: {}
};

const useStyles = makeStyles(styles);

function GridContainer(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  let spacing = props.spacing;
  if (!spacing) {
    // eslint-disable-next-line react/prop-types
    spacing = DESIGN_UTILS.getGridSpacing(props.width);
  }

  return (
    <Grid
      container
      spacing={spacing}
      {...rest}
      className={clsx(classes.grid, className, {
        'debug--grid-container': props.debug
      })}
    >
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: ''
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  debug: PropTypes.bool,
  spacing: PropTypes.number
};

export default withWidth()(GridContainer);
