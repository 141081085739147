import React from 'react';
import PhoneInput from 'react-phone-number-input/input-mobile';
import PropTypes from 'prop-types';

const ReactPhoneInput = ({ inputRef, ...rest }) => {
  return <PhoneInput {...rest} ref={inputRef} defaultCountry="US" />;
};

ReactPhoneInput.propTypes = {
  inputRef: PropTypes.func
};

export default ReactPhoneInput;
