import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography
} from '@material-ui/core'
import { COLORS } from 'config/constants';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0)
    }
  },
  card: {
    width: '100%'
  },
  media: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    margin: theme.spacing(3, 5, 7),
    height: 71,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 5, 3),
      height: 40
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 5, 5),
      height: 60
    }
  },
  icon: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  mainText: {
    color: COLORS.darkGray,
    textTransform: 'uppercase',
    marginBottom: 36,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
    textAlign: 'center',
  },
  descriptionText: {

  },
  buttonText: {
    marginLeft: theme.spacing(1),
    color: COLORS.blue,
    textDecoration: 'underline',
    letterSpacing: 1,
    cursor: 'pointer'
  },
  cardContent: {
    padding: theme.spacing(2, 1)
  }
}));

const CardView = props => {
  const { image, title, description, buttonText } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)}>
      <Card
        className={classes.card}
        elevation={0}
        raised={false}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.media}>
            {image && 
              <img
                alt={'img'}
                className={classes.icon}
                src={image}
              />
            }
          </div>
          <Typography
            className={classes.mainText}
            variant={'h3'}
          >
            {title}
          </Typography>
          <Typography
            className={classes.descriptionText}
            variant="subtitle2"
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography
            className={classes.buttonText}
            onClick={props.onClick}
            variant={'subtitle1'}
          >
            {buttonText}
          </Typography>
        </CardActions>
      </Card>
    </div>

  );
};

CardView.defaultProps = {

};

CardView.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default CardView;
