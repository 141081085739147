import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';

import theme from './theme';
import configureStore from './store';
import routes from './routes';
import './assets/scss/index.scss';
import analytics from './utils/analytics';

const history = createBrowserHistory({
  scrollRestoration: 'manual'
});
const store = configureStore(history);

history.listen(() => {
  analytics.page();
});

const App = () => {
  return (
    <HelmetProvider>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>{renderRoutes(routes)}</Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StoreProvider>
    </HelmetProvider>
  );
};

export default App;
