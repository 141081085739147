import { handleActions } from 'redux-actions';
import {GLOBALS} from 'config/constants';
import {resetAuthHeaders} from 'api/axios';

const initState = {
  firstLoad: true,
  isAuthenticated: false,
  fetching: false,
  user: null
};

export default handleActions(
  {
    LOGIN: state => {
      return {
        ...state,
        firstLoad: true,
        fetching: true,
        isAuthenticated: false,
      };
    },

    LOGOUT: () => {
      localStorage.removeItem(GLOBALS.FTI_TOKEN);
      resetAuthHeaders();
      return initState;
    }
  },
  initState
)
