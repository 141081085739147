/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, MenuItem, Box } from '@material-ui/core';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { SelectFieldWrapper } from './Wrapper';

const useStyles = makeStyles(styles);

//
const RenderField = ({
  multiple,
  handleError,
  fieldRenderProps,
  labelWidth,
  ...rest
}) => {
  const { meta, input } = fieldRenderProps;
  input.multiple = multiple;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  useEffect(() => {
    handleError(showError ? meta.error : null);
  });

  return (
    <SelectFieldWrapper
      labelWidth={labelWidth}
      {...fieldRenderProps}
      {...rest}
    />
  );
};

RenderField.defaultProps = {
  multiple: false,
  handleError: () => {},
  fieldRenderProps: {},
  labelWidth: 0
};

RenderField.propTypes = {
  fieldRenderProps: PropTypes.any,
  handleError: PropTypes.func,
  labelWidth: PropTypes.number,
  multiple: PropTypes.bool
};

const SelectField = ({
  name,
  label,
  data,
  children,
  required,
  multiple,
  fieldProps,
  formControlProps,
  formHelperTextProps,
  menuItemProps,
  labelWidth,
  handleChange,
  ...restSelectProps
}) => {
  if (!data && !children) {
    throw new Error(
      'Please specify either children or data as an attribute to the Select component.'
    );
  }

  // This is for supporting the special case of variant="outlined"
  // Fixes: https://github.com/lookfirst/mui-rff/issues/91
  const { variant } = restSelectProps;
  const [error, setError] = useState(null);
  const [labelWidthState, setLabelWidthState] = useState(0);
  const inputLabel = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidthState(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl
      error={!!error}
      fullWidth
      required={required}
      {...formControlProps}
    >
      {label && (
        <Box
          className={classes.label}
          component={'label'}
          htmlFor={name}
        >
          {label}
        </Box>
      )}
      <Field
        name={name}
        render={(fieldRenderProps) => (
          <RenderField
            fieldRenderProps={fieldRenderProps}
            handleChange={handleChange}
            handleError={(v) => setError(v)}
            labelWidth={variant === 'outlined' ? labelWidthState : labelWidth}
            multiple={multiple}
            {...restSelectProps}
          />
        )}
        {...fieldProps}
      >
        {/* eslint-disable */}
        {data
          ? data.map((item) => (
              <MenuItem key={item.value} value={item.value} {...menuItemProps}>
                {item.label}
              </MenuItem>
            ))
          : children}
      </Field>
      {error ? (
        <FormHelperText className={classes.helperText} {...formHelperTextProps}>
          {error}
        </FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

SelectField.deafaultProps = {
  name: '',
  label: '',
  data: {},
  children: null,
  required: false,
  multiple: false,
  fieldProps: {},
  formControlProps: {},
  formHelperTextProps: {},
  menuItemProps: {},
  labelWidth: 0,
  handleChange: () => {}
};

SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  children: PropTypes.node,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  fieldProps: PropTypes.objectOf(PropTypes.any),
  formControlProps: PropTypes.objectOf(PropTypes.any),
  formHelperTextProps: PropTypes.objectOf(PropTypes.any),
  menuItemProps: PropTypes.objectOf(PropTypes.any),
  labelWidth: PropTypes.any,
  handleChange: PropTypes.func
};

export default SelectField;
