import React, {Fragment, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {useRouter} from 'utils';
import {GLOBALS} from 'config/constants';
import {SessionActions} from 'store/actions';

const AuthGuard = props => {
  const { auth, children } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  useEffect(() => {
    if (auth) {
      const token = localStorage.getItem(GLOBALS.FTI_TOKEN);
      if (!token) router.history.replace('/presignup');
      if (session.firstLoad) {
        dispatch(SessionActions.me());
      } else if (!session.isAuthenticated || !session.user) {
        router.history.replace('/presignup');
      }
    }
  }, [router]);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

AuthGuard.defaultProps = {
  auth: false
};

AuthGuard.propTypes = {
  auth: PropTypes.bool,
  children: PropTypes.node
};

export default AuthGuard;
