import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { COLORS } from 'config/constants';

const useStyles = makeStyles(theme => ({
  root: {
    height: '57vw',
    maxHeight: 757,
    minHeight: 300
  },
  cover: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.breakpoints.sm,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundPosition: '22% top',
    },
    backgroundColor: COLORS.darkBlue
  },
  content: {
    flex: 1
  },
}));

const Banner = props => {
  const classes = useStyles();
  const { className } = props;
  return (
    <div className={classes.root}>
      <div className={clsx(classes.cover, className)}>
        <div className={classes.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Banner;
