import React from 'react';
import PropTypes from 'prop-types';
import { isIOS, isMacOs } from 'react-device-detect';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from 'config/constants';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    backgroundColor: COLORS.white
  },
  reactPlayer: {
    display: 'flex'
  },
  player: {
    paddingBottom: 0
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

const FtYoutube = ({
  useReactPlayer,
  source,
  maskStyle,
  children,
  rootStyle
}) => {
  const classes = useStyles();
  const streamType = isIOS || isMacOs ? 'hls' : 'dash';
  const url = useReactPlayer
    ? source
    : `https://player.cloudinary.com/embed/?public_id=${source}&cloud_name=fintron&player%5Bfluid%5D=true&player%5Bcontrols%5D=false&player%5Bmuted%5D=true&player%5Bfloating_when_not_visible%5D=right&player%5Bautoplay%5D=true&player%5Bloop%5D=true&source%5Bsource_types%5D%5B0%5D=${streamType}&&player%5Bposter_options%5D%5Btransformation%5D%5Bstart_offset%5D=0`;

  return (
    <Box
      className={clsx(
        classes.root,
        useReactPlayer && classes.player,
        rootStyle
      )}
    >
      {useReactPlayer ? (
        <ReactPlayer
          className={classes.reactPlayer}
          controls={false}
          height="100%"
          loop
          muted
          onError={(e) => console.log({ e })}
          playing
          playsinline
          stopOnUnmount={false}
          url={url}
          width="100%"
        />
      ) : (
        <Box
          allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
          allowFullScreen
          className={classes.video}
          component={'iframe'}
          frameBorder="0"
          src={url}
          title="youtube-iframe"
        />
      )}
      <Box className={clsx(classes.mask, maskStyle)}>{children}</Box>
    </Box>
  );
};

FtYoutube.defaultProps = {
  useReactPlayer: false,
  source: '',
  maskStyle: '',
  rootStyle: ''
};

FtYoutube.propTypes = {
  children: PropTypes.node,
  maskStyle: PropTypes.string,
  rootStyle: PropTypes.string,
  source: PropTypes.string,
  useReactPlayer: PropTypes.bool
};

export default FtYoutube;
