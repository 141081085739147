import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: 0,
    background: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    padding: 0,
    height: theme.spacing(7),

    '& .MuiTouchRipple-root': {
      display: 'none',
    }
  },
}));

const ImageButton = props => {
  const { children, className, href, onClick, src, type } = props;
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className)}
      href={href}
      onClick={onClick}
      style={{ backgroundImage: `url(${src})` }}
      type={type}
    >
      {children}
    </Button>
  );
};

ImageButton.defaultProps = {
  type: 'button'
};

ImageButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string,
  type: PropTypes.string,
};

export default ImageButton;
