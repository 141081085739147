import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { COLORS, FONTS } from 'config/constants';
import clsx from 'clsx';
import { CustomButton } from '../index';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    borderRadius: 0,
    backgroundColor: 'transparent'
  },
  header: {
    color: COLORS.darkBlueText,
    fontFamily: FONTS.Oswald,
    fontWeight: 300,
    fontSize: 48,
    lineHeight: 1.25,
    textTransform: 'uppercase',
    [theme.breakpoints.up('xl')]: {
      fontSize: 58,
      lineHeight: 0.94
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 38,
      lineHeight: 1.29,
      textAlign: 'center'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    marginTop: 10
  },
  description: {
    color: COLORS.black,
    fontFamily: FONTS.Roboto,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.36,
    margin: '10px 0px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 22,
      lineHeight: 1.3,
      letterSpacing: 0.44,
      margin: '30px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: 0.28,
      margin: '20px 0px'
    }
  },
  buttonDefault: {
    width: '100%',
    height: 44,
    margin: '15px 0px 3px 0px',
    [theme.breakpoints.up('xl')]: {
      height: 54
    },
    [theme.breakpoints.down('sm')]: {
      height: 33,
      margin: '20px 0px 3px 0px'
    }
  }
}));

const ButtonCard = ({
  rootStyle,
  headerStyle,
  imageContainerStyle,
  imageStyle,
  descStyle,
  buttonContainer,
  data,
  isMobile,
  imageComponent,
  children,
  buttonOptions,
  invertButtonTheme
}) => {
  const { topic, description, action, image = '' } = data;
  const { disabled, type, onClick } = buttonOptions;
  const classes = useStyles();
  // For images passed as string such as PNGs
  const isImageVisible = isMobile && image;
  // For images passed as components such as SVGs
  const showImage = isMobile && imageComponent;

  return (
    <Box className={clsx(classes.root, rootStyle)}>
      <Card
        className={classes.card}
        elevation={0}
      >
        <Typography className={clsx(classes.header, 'multiline', headerStyle)}>
          {topic}
        </Typography>
        {isImageVisible && (
          <Box className={clsx(classes.imageContainer, imageContainerStyle)}>
            <Box
              className={clsx(classes.image, imageStyle)}
              component="img"
              src={image}
            />
          </Box>
        )}
        {showImage && (
          <Box className={clsx(classes.imageContainer, imageContainerStyle)}>
            {imageComponent}
          </Box>
        )}
        {description && (
          <Typography
            className={clsx(classes.description, 'multiline', descStyle)}
          >
            {description}
          </Typography>
        )}
        {children}
        {action && (
          <Box className={clsx(classes.buttonDefault, buttonContainer)}>
            <CustomButton
              disabled={disabled}
              inverseTheme={invertButtonTheme}
              onClick={onClick}
              rounded
              title={action}
              type={type}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
};

ButtonCard.defaultProps = {
  rootStyle: '',
  headerStyle: '',
  imageContainerStyle: '',
  imageStyle: '',
  descStyle: '',
  buttonContainer: '',
  data: {},
  isMobile: false,
  imageComponent: null,
  children: null,
  buttonOptions: {},
  invertButtonTheme: false
};

ButtonCard.propTypes = {
  buttonContainer: PropTypes.string,
  buttonOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string
  }),
  children: PropTypes.node,
  data: PropTypes.shape({
    topic: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    action: PropTypes.string,
    image: PropTypes.string
  }),
  descStyle: PropTypes.string,
  headerStyle: PropTypes.string,
  imageComponent: PropTypes.node,
  imageContainerStyle: PropTypes.string,
  imageStyle: PropTypes.string,
  invertButtonTheme: PropTypes.bool,
  isMobile: PropTypes.bool,
  rootStyle: PropTypes.string
};

export default ButtonCard;
