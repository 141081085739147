const menuItems = [
  /* Temporarily disabled */
  // {
  //   title: 'INVESTING',
  //   className: 'menuButton',
  //   redirect: '/investing'
  // },
  // {
  //   title: 'LEARNING',
  //   className: 'menuButton',
  //   redirect: '/learn'
  // },
  {
    title: 'For Businesses',
    className: 'menuButton',
    redirect: '/home',
    location: '/consumer'
  },
  {
    title: 'For Consumers',
    className: 'menuButton',
    redirect: '/consumer',
    location: '/home'
  },
  {
    title: 'Developer Docs',
    className: 'menuButton',
    redirect: '',
    location: '/home'
  },
  {
    title: 'Contact Sales',
    className: 'menuButton',
    redirect: '/contact-sales',
    location: '/home'
  },
  {
    title: 'Sign Up',
    className: 'menuButton',
    redirect: '',
    location: '/consumer'
  }
];

const footerItems = [
  {
    text: 'PRIVACY POLICY',
    link: '/pdfs/fintron_invest_privacy_policy.pdf',
    target: '_self'
  },
  {
    text: 'TERMS & CONDITIONS',
    link: '/pdfs/fintron_invest_terms_of_service.pdf',
    target: '_self'
  },
  {
    text: 'CUSTOMER RELATIONSHIP SUMMARY',
    link: '/pdfs/fintron_invest_llc_form_crs.pdf',
    target: '_self'
  },
  {
    text: 'DISCLOSURES',
    link: '/disclosures',
    target: '_self'
  },
  {
    text: 'CONTACT US',
    link: '/contact-us',
    target: '_self'
  }
];

export default {
  menuItems,
  footerItems
};
