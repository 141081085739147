import React, { useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import data from './data';
import { useStyles } from './styles';
import clsx from 'clsx';

const SectionSponsors = () => {
  const [hovered, setHovered] = useState('');
  const classes = useStyles();
  const { sponsors } = data;

  return (
    <Grid className={classes.spContainer} container>
      {sponsors.map(({ key, logoGray, logoColor }) => (
        <Grid
          key={key}
          className={clsx(
            classes.spIconContainer,
            ['webster', 'apex'].includes(key) &&
              classes.spIconContainerMarginTop
          )}
          item
          xs={4}
        >
          <IconButton
            className={clsx(
              classes.spIcon,
              ['webster', 'apex'].includes(key) && classes.spIconMobile
            )}
            onMouseEnter={() => setHovered(key)}
            onMouseLeave={() => setHovered('')}
            disableTouchRipple
            disableFocusRipple
            disableRipple
          >
            {key === hovered ? logoColor : logoGray}
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default SectionSponsors;
