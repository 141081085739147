import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

function pxToRem(value) {
  return `${value / 16}rem`;
}

export default {
  h1: {
    fontSize: pxToRem(100),
    [breakpoints.down('md')]: {
      fontSize: pxToRem(72)
    },
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(56)
    },
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(38)
    },
  },
  h2: {
    fontSize: pxToRem(52),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(44)
    },
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(30)
    },
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(28)
    },
  },
  h3: {
    fontSize: pxToRem(32),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(22)
    }
  },
  h5: {
    fontSize: pxToRem(20),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(14)
    }
  },
  body2: {
    fontSize: pxToRem(30),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(16)
    }
  }
};
