import { PreSignupApi } from 'api';
import { call, put } from 'redux-saga/effects';
import { MessageActions } from 'store/actions';
import { PreRegActions } from 'store/reducers';

export function* getAppRequest({
  payload: { phoneNumber: phone_number, successCb }
}) {
  try {
    yield put(PreRegActions.setIsLoading(true));
    const response = yield call(PreSignupApi.getTheApp({ phone_number }));
    const { status } = response;
    status === 200 && successCb();
    yield put(PreRegActions.setIsLoading(false));
  } catch (error) {
    console.log({ error });
    let apiErrorMessage = '';

    if (error?.response) {
      const {
        response: {
          data: { error }
        }
      } = error;

      apiErrorMessage = error;
    } else {
      apiErrorMessage = error.message;
    }
    yield put(PreRegActions.setIsLoading(false));
    yield put(
      MessageActions.showMessage({
        title: 'Failed to register!',
        content: apiErrorMessage,
        action: 'Dismiss'
      })
    );
  }
}
