import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Footer } from 'layouts/MainLayout';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden'
    }
  }
}));

const Page = ({ className, title, children, footerClass }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Helmet>
        <Box component={'title'}>{title}</Box>
      </Helmet>
      {children}
      <Footer footerRootClass={footerClass} />
    </Box>
  );
};

Page.defaultProps = {
  className: '',
  footerClass: '',
  title: '',
  children: null
};

Page.propTypes = {
  className: PropTypes.string,
  footerClass: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default Page;
