import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme=> ({
  card: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  cardContent: {
    width: '100%',
    padding: 0
  },
  media: {
    maxHeight: 450,
  },
  mediaTitle: {
    fontSize: theme.spacing(3),
    paddingTop: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2),
      paddingTop: theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      paddingTop: theme.spacing(2)
    }
  }
}));

const SlideCard = props => {

  const { img, title } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, props.className)}>
      <CardContent className={classes.cardContent}>
        <CardMedia
          alt={title}
          className={classes.media}
          image={img}
          style={{
            height: props.height
          }}
        />
        <Typography
          className={classes.mediaTitle}
          variant="subtitle1"
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

SlideCard.defaultProps = {

};

SlideCard.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  img: PropTypes.string,
  title: PropTypes.string,
};

export default SlideCard;
