import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, Select as MuiSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from '../styles';
import { ArrowDown } from 'assets/images/svgs';
import clsx from 'clsx';

const useStyles = makeStyles(styles);

const SelectWrapper = ({
  input: { name, onChange, ...restInput },
  // meta,
  handleChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiSelect
      IconComponent={ArrowDown}
      className={clsx(
        classes.selectInput,
        restInput.value === '' && classes.selectInputPlaceholder
      )}
      displayEmpty
      input={<InputBase />}
      inputProps={restInput}
      name={name}
      onChange={(event) => {
        onChange(event);
        handleChange && handleChange(event);
      }}
      renderValue={restInput.value === '' ? () => rest.placeholder : undefined}
      {...rest}
    />
  );
};

SelectWrapper.defaultProps = {
  input: {},
  meta: {},
  handleChange: () => {}
};

SelectWrapper.propTypes = {
  handleChange: PropTypes.func,
  input: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.any
};

export default SelectWrapper;
