import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { DESIGNS } from 'config/constants';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const { pageSpacing } = DESIGNS;

  return {
    root: {
      flex: 1,
      overflow: 'hidden',
      // mobile
      [theme.breakpoints.down('sm')]: {},
      // tablet
      [theme.breakpoints.between('sm', 'md')]: {},
      // small laptop
      [theme.breakpoints.between('md', 'lg')]: {},
      // large laptop
      [theme.breakpoints.between('lg', 'xl')]: {},
      // extra large screen
      [theme.breakpoints.up('xl')]: {}
    },
    main: {},
    topWrap: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    debugWrap: {
      border: '1px solid green'
    },
    debugContent: {
      border: '1px dashed yellow'
    },
    leftOnly: {
      paddingLeft: theme.spacing(pageSpacing.lg),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(pageSpacing.xs)
      },
      [theme.breakpoints.between('sm', 'md')]: {
        paddingLeft: theme.spacing(pageSpacing.sm)
      },
      [theme.breakpoints.between('md', 'lg')]: {
        paddingLeft: theme.spacing(pageSpacing.md)
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        paddingLeft: theme.spacing(pageSpacing.lg)
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: theme.spacing(pageSpacing.xl)
      }
    },
    rightOnly: {
      paddingRight: theme.spacing(pageSpacing.lg),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(pageSpacing.xs)
      },
      [theme.breakpoints.between('sm', 'md')]: {
        paddingRight: theme.spacing(pageSpacing.sm)
      },
      [theme.breakpoints.between('md', 'lg')]: {
        paddingRight: theme.spacing(pageSpacing.md)
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        paddingRight: theme.spacing(pageSpacing.lg)
      },
      [theme.breakpoints.up('xl')]: {
        paddingRight: theme.spacing(pageSpacing.xl)
      }
    }
  };
});

/**
 * Magic Container: sets the responsive horizontal padding
 * Here are props to use.
 * @leftOnly: {bool} apply only left padding
 * @rightOnly: {bool} apply only right padding
 * @top: {bool} sets the top padding of the container
 * @debug: {bool} shows border black if TRUE
 * @styles: {object} sets the styles of the container:
 *    you can set background color or background image
 * */
const MagicContainer = ({
  top,
  rightOnly,
  leftOnly,
  debug,
  rootStyle,
  mainStyle,
  children
}) => {
  const classes = useStyles();
  const both = !leftOnly && !rightOnly;
  const mainCls = clsx(
    classes.main,
    {
      [classes.topWrap]: top,
      [classes.leftOnly]: leftOnly || both,
      [classes.rightOnly]: rightOnly || both,
      [classes.debugWrap]: debug
    },
    mainStyle
  );
  const contentCls = clsx('debug--magic');

  return (
    <Box className={clsx(classes.root, rootStyle)}>
      <Box className={mainCls}>
        <Box className={contentCls}>{children}</Box>
      </Box>
    </Box>
  );
};

MagicContainer.defaultProps = {
  top: false,
  rightOnly: false,
  leftOnly: false,
  debug: false,
  rootStyle: '',
  mainStyle: ''
};

MagicContainer.propTypes = {
  top: PropTypes.bool,
  rightOnly: PropTypes.bool,
  leftOnly: PropTypes.bool,
  debug: PropTypes.bool,
  rootStyle: PropTypes.string,
  mainStyle: PropTypes.string,
  children: PropTypes.node
};

export default MagicContainer;
