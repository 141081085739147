import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const grey = '#a9a4a4';
const greyLight = '#e7e6e6';
const greyLightSuper = '#E5E9EC';
const greyish = '#D4D5D7';
const black = '#000000';
const blue = '#26b1e6';
const darkBlue = '#0c233f';
const middleDarkBule = '#09223D';
const lightBlue = '#26b1e6';
const darkGray = colors.blueGrey[900];
const grayishLightBlue = '#e6e9eb';

// New
const blue1 = '#26A7DF';
const grey1 = '#B3B3B3';
const grey2 = '#00000033';
const darkBlueText = '#0B243F';
const fintronBlue = '#26A8DF';

export default {
  black,
  white,
  grey,
  greyLight,
  greyLightSuper,
  greyish,
  blue,
  darkBlue,
  middleDarkBule,
  lightBlue,
  darkGray,
  grayishLightBlue,
  // New
  blue1,
  grey1,
  grey2,
  darkBlueText,
  fintronBlue
};
