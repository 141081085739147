import { get, set } from 'lodash';


/**
 * Wraps the execution of a Yup schema to return an object
 * where the key is the form field and the value is the error string.
 */
export function makeValidate(validator) {
  return async (values) => {
    try {
      await validator.validate(values, { abortEarly: false });
    } catch (err) {
      return err.inner.reduce((errors, { path, message }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + ' ' + message);
        } else {
          set(errors, path, message);
        }
        return errors;
      }, {});
    }
  };
}

/**
 * Uses the private _exclusive field in the schema to get whether or not
 * the field is marked as required or not.
 */
export function makeRequired(schema) {
  const fields = (schema).fields;
  return Object.keys(fields).reduce((accu, field) => {
    accu[field] = fields[field]._exclusive.required;
    return accu;
  }, {} );
}
