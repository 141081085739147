import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  isWidthDown,
  Toolbar,
  withWidth
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { COLORS, GLOBALS } from 'config/constants';
import Menu from './data';
import { DESIGNS } from 'config/constants';
import { LogoTextWhite, LogoWhite } from 'assets/images/svgs';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import NavDrawer from './NavDrawer';

const { pageSpacing } = DESIGNS;

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    height: 115,
    position: 'relative',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      height: 90
    }
  },
  toolbar: {
    height: '100%',
    padding: theme.spacing(2, pageSpacing.md),
    [theme.breakpoints.down('sm')]: {
      padding: '22px 36px',
      height: 90,
      width: '100vw'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingRight: theme.spacing(pageSpacing.sm),
      paddingLeft: theme.spacing(pageSpacing.sm)
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingRight: theme.spacing(pageSpacing.md),
      paddingLeft: theme.spacing(pageSpacing.md)
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: 139,
      paddingLeft: 139
    }
  },
  logoContainer: {
    width: 90,

    '& img': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: 100
    },
    [theme.breakpoints.up('lg')]: {
      width: 114
    },
    [theme.breakpoints.down('sm')]: {
      width: 30
    }
  },
  grow: {
    flexGrow: 1
  },
  menuBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  menuButton: {
    color: COLORS.fintronBlue,
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 'bold',
    letterSpacing: 1.2,
    marginLeft: 35,
    padding: '6px',
    borderRadius: 0,
    textTransform: 'uppercase',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: 2,
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 'bold'
    }
  },
  activeMenu: {
    '&::before': {
      transform: 'translateX(-50%)',
      borderRadius: '100%',
      position: 'absolute',
      background: 'white',
      bottom: '-5px',
      height: '6px',
      width: '6px',
      content: '""',
      left: '50%'
    }
  },
  signupButton: {
    borderRadius: 50,
    backgroundColor: COLORS.fintronBlue,
    color: COLORS.white,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    padding: '7px 15px',
    // Can be enabled later when list items increase and hamburger icon is enabled again
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: COLORS.fintronBlue,
      color: COLORS.white
    }
  },
  hamburgerIcon: {
    display: 'flex',
    width: 35,
    height: 35,
    position: 'absolute',
    right: '36px',
    zIndex: theme.zIndex.drawer + 1,
    color: COLORS.fintronBlue,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  hamburgerIconOpen: {
    display: 'flex',
    width: 35,
    height: 35,
    position: 'absolute',
    right: '36px',
    zIndex: theme.zIndex.drawer + 1,
    color: COLORS.white,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const ScrollableHeader = ({ path, width, isTransparent }) => {
  const location = useLocation();

  const isMobile = isWidthDown('sm', width);
  const [open, setOpen] = useState(false);
  const classes = useStyles({ isTransparent });
  return (
    <Box className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <Link to="/home">
            {isMobile ? (
              <LogoWhite color={COLORS.fintronBlue} />
            ) : (
              <LogoTextWhite color={COLORS.fintronBlue} />
            )}
          </Link>
        </Box>
        <Box className={classes.grow} />
        <Box className={classes.menuBar}>
          {Menu.menuItems.map((menu, index) => {
            if (
              menu.title === 'Sign Up' &&
              location.pathname == menu.location
            ) {
              return (
                <Button
                  key={index}
                  className={clsx(
                    classes[menu.className],
                    classes.signupButton
                  )}
                  onClick={() => window.open(GLOBALS.APP_STORE_LINK, '_self')}
                >
                  {menu.title}
                </Button>
              );
            } else if (
              (menu.title === 'Developer Docs' &&
                location.pathname === '/home') ||
              (menu.title === 'Developer Docs' &&
                location.pathname === '/contact-sales')
            ) {
              return (
                <a
                  key={index}
                  // eslint-disable-next-line no-undef
                  href={process.env.REACT_APP_API_DOCS_LINK}
                  rel="noopener noreferer"
                >
                  <Button className={clsx(classes[menu.className])}>
                    {menu.title}
                  </Button>
                </a>
              );
            } else if (
              menu.title === 'Contact Sales' &&
              location.pathname == menu.location
            ) {
              return (
                <Link key={index} to="/contact-sales">
                  <Button
                    className={clsx(
                      classes[menu.className],
                      classes.signupButton
                    )}
                  >
                    {menu.title}
                  </Button>
                </Link>
              );
            } else if (
              menu.title !== 'Contact Sales' &&
              location.pathname == menu.location
            ) {
              return (
                <Link key={index} to={menu.redirect}>
                  <Button
                    className={clsx(
                      classes[menu.className],
                      path === menu.redirect && classes.activeMenu
                    )}
                  >
                    {menu.title}
                  </Button>
                </Link>
              );
            } else if (
              (menu.title !== 'Developer Docs' &&
                location.pathname == '/contact-us') ||
              (location.pathname == '/contact-sales' &&
                menu.redirect !== location.pathname)
            ) {
              return (
                <Link key={index} to={menu.redirect}>
                  <Button
                    className={clsx(
                      classes[menu.className],
                      menu.title === 'Sign Up' && classes.signupButton
                    )}
                  >
                    {menu.title}
                  </Button>
                </Link>
              );
            }
          })}
          <MenuIcon
            className={clsx(
              open === true ? classes.hamburgerIconOpen : classes.hamburgerIcon
            )}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((v) => !v);
            }}
          />
        </Box>
        <NavDrawer path={path} open={open} closeDrawer={() => setOpen(false)} />
      </Toolbar>
    </Box>
  );
};

ScrollableHeader.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string,
  isTransparent: PropTypes.bool
};

export default withWidth()(ScrollableHeader);
