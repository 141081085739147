import React from 'react';

import {
  BgSocial,
  BgSocialMobile,
  BgFooter,
  BgFooterMobile,
  BgSocialB2B,
  BgSocialB2BMobile
} from 'assets/images/pngs';
import {
  IconInstagram,
  IconFacebook,
  IconTiktok,
  IconTwitter,
  IconLinkedin,
  LogoSageGray,
  LogoConnecticutGray,
  LogoAuaGray,
  LogoWebsterGray,
  LogoApexGray,
  LogoSageColor,
  LogoConnecticutColor,
  LogoAuaColor,
  LogoWebsterColor,
  LogoApexColor
} from 'assets/images/svgs';

export default {
  bgSocialSection: BgSocial,
  bgSocialSectionMobile: BgSocialMobile,
  bgSocialSectionB2B: BgSocialB2B,
  bgSocialSectionB2BMobile: BgSocialB2BMobile,
  socials: [
    {
      key: 'instagram',
      icon: <IconInstagram />,
      url: 'https://www.instagram.com/fintroninvest/',
      target: '_self'
    },
    {
      key: 'facebook',
      icon: <IconFacebook />,
      url: 'https://www.facebook.com/FinTronApp',
      target: '_self'
    },
    {
      key: 'tiktok',
      icon: <IconTiktok />,
      url: 'https://www.tiktok.com/@fintroninvest',
      target: '_self'
    },
    {
      key: 'twitter',
      icon: <IconTwitter />,
      url: 'https://twitter.com/FinTronInvest',
      target: '_self'
    },
    {
      key: 'linkedin',
      icon: <IconLinkedin />,
      url: 'https://www.linkedin.com/company/23685944/admin/',
      target: '_self'
    }
  ],
  sponsors: [
    { key: 'sage', logoGray: <LogoSageGray />, logoColor: <LogoSageColor /> },
    {
      key: 'connecticut',
      logoGray: <LogoConnecticutGray />,
      logoColor: <LogoConnecticutColor />
    },
    { key: 'aua', logoGray: <LogoAuaGray />, logoColor: <LogoAuaColor /> },
    {
      key: 'webster',
      logoGray: <LogoWebsterGray />,
      logoColor: <LogoWebsterColor />
    },
    { key: 'apex', logoGray: <LogoApexGray />, logoColor: <LogoApexColor /> }
  ],
  bgFooterSection: BgFooter,
  bgFooterSectionMobile: BgFooterMobile,
  logo: '',
  footerAction: 'Get the app',
  footerLinks: [
    {
      text: 'VIEW FINTRON INVEST\'S BACKGROUND ON BROKERCHECK',
      link: 'https://brokercheck.finra.org/firm/summary/305818',
      target: '_self'
    },
    {
      text: 'PRIVACY POLICY',
      link: '/pdfs/fintron_invest_privacy_policy.pdf',
      target: '_self'
    },
    {
      text: 'TERMS OF SERVICE',
      link: '/pdfs/fintron_invest_terms_of_service.pdf',
      target: '_self'
    },
    {
      text: 'CUSTOMER RELATIONSHIP SUMMARY',
      link: '/pdfs/fintron_invest_llc_form_crs.pdf',
      target: '_self'
    },
    { text: 'DISCLOSURES', link: '/disclosures', target: '_self' },
    { text: 'CONTACT US', link: '/contact-us', target: '_self' }
  ],
  footerText: [
    <>
      Securities trading is offered to self-directed customers by FinTron Invest, LLC, a broker dealer registered with the Securities and Exchange Commission (SEC). FinTron Invest LLC is a member of the Financial Industry Regulatory Authority (
      <a
        href="https://www.finra.org/#/"
        target="_self"
      >
        FINRA
      </a>) &nbsp;
      Securities Investor Protection Corporation (
      <a
        href="https://www.sipc.org/"
        target="_self"
      >
        SIPC
      </a>)
      
      Advisory accounts and services are provided by FinTron Advisors, LLC (also known as "FinTron Advisors"). FinTron Advisors is an Investment Advisor registered with and regulated by the SEC under the Investment Advisors Act of 1940. Registration does not imply a level of skill or training. See additional information on the Disclosures webpage. Trades in your FinTron Advisors account are executed by FinTron Invest LLC.
      FinTron Invest LLC is a member of SIPC, which protects securities customers of its members up to $500,000 (including $250,000 in any cash awaiting reinvestment). An explanatory brochure is available upon request or at 
      &nbsp;<a
        href="https://www.sipc.org/"
        target="_self"
      >
    www.sipc.org. 
      </a>&nbsp;

      FinTron Invest LLC’s clearing firm Apex Clearing Corp has purchased an additional insurance policy. Similar to SIPC protection, this additional insurance does not protect against a loss in the market value of securities.
      
      All investments involve risk, and not all risks are suitable for every investor. The value of securities may fluctuate and as a result, clients may lose more than their original investment. The past performance of a security, or financial product does not guarantee future results or returns. Keep in mind that while diversification may help spread risk, it does not assure a profit or protect against loss in a down market. There is always the potential of losing money when you invest in securities or other financial products. Investors should consider their investment objectives and risks carefully before investing.
      
      Investors should be aware that system response, execution price, speed, liquidity, market data, and account access times are affected by many factors, including market volatility, size and type of order, market conditions, system performance, and other factors. Market volatility, volume and system availability may delay account access and trade executions.
      
      No content on the FinTron Invest LLC website shall be considered as a recommendation or solicitation for the purchase or sale of securities or other investment products. All information and data on the website is for reference only and no historical data shall be considered as the basis for judging future trends.
    </>
  ]
};
